import { onBeforeUnmount, onMounted } from 'vue';

export class IdleTimer {
    static lastInteractionMs = Date.now();

    static updateUserInteractionTs = () => {
        this.lastInteractionMs = Date.now();
    };

    static setup() {
        window.addEventListener('mousemove', this.updateUserInteractionTs);
        window.addEventListener('keydown', this.updateUserInteractionTs);
        window.addEventListener('scroll', this.updateUserInteractionTs);
        window.addEventListener('click', this.updateUserInteractionTs);
    }

    static teardown() {
        window.addEventListener('mousemove', this.updateUserInteractionTs);
        window.addEventListener('keydown', this.updateUserInteractionTs);
        window.addEventListener('scroll', this.updateUserInteractionTs);
        window.addEventListener('click', this.updateUserInteractionTs);
    }

    static getIdleMs() {
        return Date.now() - this.lastInteractionMs;
    }
}

export function useIdleTimeout(options: { checkIntervalMs?: number; timeoutMs: number; onIdle: (idleMs: number) => void }) {
    let checkInteractionInterval: number | undefined;

    const checkLastInteractionTs = () => {
        const idleMs = Date.now() - IdleTimer.lastInteractionMs;
        if (idleMs >= options.timeoutMs) {
            options.onIdle(idleMs);
        }
    };

    onMounted(() => {
        checkInteractionInterval = window.setInterval(checkLastInteractionTs, options.checkIntervalMs ?? 10_000);
    });

    onBeforeUnmount(() => {
        if (checkInteractionInterval) clearInterval(checkInteractionInterval);
    });
}

IdleTimer.setup();
